import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import Banner from './Sections/Banner/component';
import SectionMaterials from './Sections/Materials/component';
import SectionMec from './Sections/Mec/component';
import SectionPartners from './Sections/Partners/component';
import SectionPublisher from './Sections/Publisher/component';
import SectionCarousel from './Sections/SectionCarousel/component';
import SectionSimple from './Sections/Simple/component';
import SectionTestimonials from './Sections/Testionials/component';
import WhatsappButton from 'components/commom/Widgets/WhatsappAppButton';
import WebTechPopup from 'components/commom/WebTechPopup';

function Home() {
  return (
    <LayoutDefault>
      <Banner />
      <SectionSimple />
      <SectionCarousel />
      <SectionMaterials />
      <SectionPublisher />
      <SectionMec />
      <SectionPartners />
      <SectionTestimonials />
      <WhatsappButton />
      {/* <WebTechPopup /> */}
    </LayoutDefault>
  );
}

export default Home;
